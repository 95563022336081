<template>
  <div class="card-grid">
    <ChartCard
      chartType="text"
      questionType="how_to_support_sustainable_travelling"
    />
    <ChartCard chartType="text" questionType="ideas_employer" />
  </div>
</template>
<script>
import ChartCard from "../chart-cards/ChartCard.vue";
export default {
  name: "feedbacks",
  metaInfo() {
    return {
      title: this.$t("dashboard.nav.feedback"),
    };
  },
  components: { ChartCard },
};
</script>
